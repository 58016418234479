.container-list{
    display:flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px 60px;
    flex-wrap: wrap;
    margin-top: 20px;
}
.product-list{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.producto-details{
    text-align: center;
    min-height: 206px;
    cursor: pointer;
}
.product-list>.img{
    width:350px;
}
.product-name{
    margin: 10px 0;
    font-size:15px;
    color:#dd4814 ;
    background-color: transparent;
    font-family:Tahoma ;
    font-weight: 500px;
}
.product-name a{
    color:#dd4814;
}
.product-name a:hover, .product-name a:focus {
    color:#97310e;
}
.product-price {
    margin: 10px;
    font-size: 16px;
}
.precio-caja{
    font-size: 18px;
}
/* .cart-icon{
    font-weight: normal;
    letter-spacing: 1px;
} */
.btn-add-to-cart{
    margin:0;
}
.btn-add-to-cart>button:disabled, .btn-add-to-cart>button[disabled]{
    cursor: not-allowed;
}
.btn-add-to-cart>button .cart-icon>span{
    padding-left: 5px;
}