
.cart{
    margin:40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    font-family:'Prompt', sans-serif;
}

.cart-empty{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.btn-start-shopping{
    width:250px;
    padding:10px;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    border:1px solid transparent;
    background-color: orange;
    border-radius:15px;
    margin-top: 20px;
    cursor: pointer;
}
.btn-start-shopping a{
    color:rgb(61, 7, 7);
    display: block;
}

.cart-full{
    /* margin:150px auto; */
    display: flex;
    gap:30px;
    width:1170px;
    justify-content: center;
}
.shopping-cart-masProductos{
    width:66%;
    display: flex;
    flex-direction: column;
}
.realizar-pedido-carrito{
    width:30%;
    display:flex;
    flex-direction: column;
}
.realizar-pedido-carrito>button{
    align-self: center;
    width: 105%;
    margin-left: 14px;
    margin-top:20px;
}
 .other-products{
   width:100%;
} 
.cart-full>form>button{
    width:200px;
    padding:10px
}

/*---------------Vista 1200px--------------*/
@media screen and (max-width:1200px){
    .cart-full{ 
        width:max(900px, 90%);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:50px;
    }
    .shopping-cart{
        width:100%;
    }
    .order-summary{
        width:50%;
    }
 
}
/*---------------Vista Tablet--------------*/
@media screen and (max-width:1024px){
    .cart-full{
    width:max(700px, 90%);
}

}
/*---------------Vista Movil--------------*/
@media screen and (max-width:768px){
    .cart-full{
        width:max(360px, 90%);
        align-items: flex-start;
    }
    .order-summary{
        width:100%;
     
    }

}
