.contenedor-detalle-pedido{
    margin: 100px auto;
    width:1170px;
    /* padding-left:30px; */
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap:30px;
    font-family: 'Prompt', sans-serif;
}
.contenedor-detalle-pedido>h2{
   align-self: center;
    color:rgb(106, 104, 104);
}
.info-nr-pedido{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
}

.info-nr-pedido span:last-of-type{
    justify-self: flex-end;
}
/*--------------Show/Hide Factura---------------*/



.info-detalle-pedido{
    display:flex;
    justify-content: flex-start;
    width:99%;
    border:1px solid rgb(210, 207, 207);
    border-radius:10px; 
    padding-left: 20px;  
    gap:45px;  
}
.info-detalle-pedido>div:first-of-type{
    width:30%;
    display:flex;
    flex-direction: column;
    gap:30px;
}
.address-envio p{
    margin: 0;
    padding-bottom: 3px;
}
.info-resumen-compras{
    width:60%;
    /* background-color: rgb(245, 241, 241) ;
    border-radius: 10px; */
    padding-left:30px;
}
.productos-del-pedido{
    display:flex;
    flex-direction: column;
}
.item-pedidos{
    display: flex;
    flex-direction: row;
    gap:30px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.item-pedidos picture{
    width:100px;
}
.item-pedidos picture>img{
    width:100%;
}
.item-pedido div{
    width:50%;
}
.item-pedidos p{
    margin:0;
    padding:5px;
}
.transporte-pedido{
    text-transform: uppercase;
}
.verfactura{
    text-align: center;
    width:10%;
    /* text-decoration: underline; */
    cursor:pointer;
    padding-top: 10px;
}
.verfactura>span{
    display: flex;
    gap:10px;
    align-items: center;
}






