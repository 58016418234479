.contenedor-confirmarPedido{
    width:1170px;
    margin:100px auto;
    font-family: 'Prompt', sans-serif;
    display:flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    border: 1px solid #EA7525;
    border-radius: 8px;
    width:1170px;
    padding-bottom: 30px;
}
.paso-confirmar{
    background-color: #EA7525;
    width: 98.5%; 
    color: white;
    font-size: 1rem;
    padding: 10px;
    font-weight: normal;
    margin: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.modoConfirmar{
    border: 1px solid #f0f0f0;
    background-color: #f5f5f5;
    font-size: 1rem;
    font-weight: 400;
    padding: 10px ;
    width: 95%;
    margin:10px;
}
.modoConfirmar>h2 span{
    color:#EA7525;
} 
.confirmar-details{
    display: flex;
    width:1000px;
    justify-content: space-between;
    align-items: strech;
    margin-top:50px;
    gap:20px;
}

/* .payment-type{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:20px;
    width:45%;
} */
.breadcrumb-payment{
    /* display: flex; */
    display: none;
    align-items: center;
    gap:20px;
    width:100%;  
    justify-content: center;
}
.envio-payment{
    border:1px solid orange;
    color:black;
    border-radius: 100%;
    padding:10px;
    width: 15px;
    height:15px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: orange;
}

.guion-payment{
   height:1px;
   background: black;
   width:150px;
}
.shipping-address{
    width:90%;
}
.shipping-address h2 {
    font-size: 1rem;
}
.shipping-address>ul{
    border:1px solid black;
    padding:0;
}
/* .shipping-address>ul li:first-of-type{
    border-bottom: 1px solid black;
} */
.info-adress, .info-envio{
    display: flex;
    justify-content: space-between;
    padding:20px;
    color:rgb(141, 137, 137);
}
.info-adress p{
    margin:0;
}

/* .info-adress a, .info-envio a {
    text-decoration: underline;
    color:orange;
} 
 .edit-info-client{
    border-radius: 15px;
    background-color: rgb(227 70 7 / 73%);;
    color:white;
    width:100px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    box-shadow: none;
    outline: none;
    line-height: 16px;
    cursor:pointer;
    margin-top: 10px;
} */
/*---------------Invoice address-----------*/
.invoice-address-block{
    display: none;
}
.invoice-address-form{
    display: none;
}
.address-invoice-info{
    display: none;
    border:1px solid black;
    padding:20px;
    margin:10px 0 10px 30px;
}
.other-address{
    display: none;
}

#invoice[type="checkbox"]{
    background-color: orange;
}
#invoice:checked ~.invoice-address-block,#invoice:checked~.invoice-address-form {
    display: block;
}
.invoice-address-block, .invoice-address-form{
    margin: 20px 30px;
}
 
#input-invoice-address:checked~.address-invoice-info{
    display: block;
}

#input-invoice-address-form:checked~.other-address{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.shipping-other{
    display: flex;
    flex-direction: column;
    gap:20px;
    width:100%;
    margin-top:10px;
}

/* .payment-type input{
    margin-right:15px;
}
.payment-metod{
    display: flex;
    flex-direction: column;
    width:90%;
}
.payment-metod>div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border:1px solid black;
}
.tarjeta, .paypal, .bizum{
    display: flex;
    justify-content: space-between;
    width:91.5%;
    padding:21px;
    border-bottom: 1px solid black;
    align-items: center;
}

.paypal-label{
    display: flex;
    flex-direction: row;
    width:100%;
    align-items: center;
}
.paypal-label label span{
    color:rgb(141, 137, 137);
}

.transfer{
    display: flex;
    flex-direction: column;
    padding: 21px;
    width: 93%;
}
.transfer>div{
    display: flex;
    justify-content: space-between;
}
.transfer-info{
    padding-left:50px;
}
.transfer-info p{
    margin:0;
}
.transfer-info p:last-of-type{
    margin-bottom:10px;
} */
.condiciones-compra{
    font-size: 0.9rem;
    padding-top:40px;
    align-self: flex-start;
}
.condiciones-compra a{
    text-decoration: underline;
}
.realizar-pedido, .btn-refresh, .btn-cancel{
    padding:10px;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 1px;
    border:1px solid transparent;
    background-color: orange;
    border-radius:15px;
    margin-top: 20px;
    cursor: pointer;
    
}
.realizar-pedido a, .btn-refresh a, .btn-cancel a{
    color:rgb(101, 43, 43);
}
.realizar-pedido{
    width:250px;
    align-self: center;
    font-size: 1rem;
}
.buttons{
    display: flex;
    justify-content: center;
    gap:50px;
    align-items:center;
    margin:20px auto;
}
.btn-refresh{
    width:150px;
}
.btn-cancel{
    width:150px;
    background-color: rgb(158, 156, 156);
}

.payment>button:last-of-type{
    margin-top:40px;
}

.otros-productos{
    cursor: pointer;
    /* align-self: flex-start; */
    padding: 10px;
    font-style: italic;
    font-weight: 700;
    width:84%;
}
.mas-productos span{
    padding-left: 10px;
    font-size: 1.6rem;
}
form{
    margin-top:50px;
}

/*---------------Vista 1200px--------------*/
@media screen and (max-width:1200px){
.contenedor-confirmarPedido{
    width:max(1024px, 100%);
}
.tarjeta, .paypal, .bizum{
    width:88%;
}
.payment-details{
    width:initial;
    gap:70px;
}
.condiciones-compra{
    margin-left: 100px;
}

}
/*---------------Vista Tablet--------------*/
@media screen and (max-width:1024px){
    .contenedor-confirmarPedido{
        width:max(769px, 100%);
        margin:70px auto;
    }
    .confirmar-details{
        gap:10px;
    }
    .payment-type{
        width:40%;
        font-size: 0.9rem;
    }
    .tarjeta img{
            width:48px;
    }
    .paypal img{
        width:64px;
    }
    .transfer img{
        width:25px;
        margin-right: 20px;;
    }
    .tarjeta, .paypal, .bizum{
        width:86%;
    }
    .transfer{
        width:93%;
        padding-right: 0;
    }
}
/*---------------Vista Movil--------------*/
@media screen and (max-width:1024px){
    .invoice-address-block, .invoice-address-form{
        margin:20px 0 20px 5px;
        width:90%;
    }
  .buttons{
    width:90%;
  }  
}
/*---------------Vista Movil--------------*/
@media screen and (max-width:768px){
    .contenedor-confirmarPedido{
        width:max(384px, 90%);
    }
    .breadcrumb-payment{
        width:80%;
    }

    .envio-payment{
        width:8px;
        height:8px;
        padding:8px;
    }
   
    .confirmar-details{
        width:90%;
        align-items: center; 
    }
    .payment-type{
        width:90%;
    }
    .shipping-address{
        width:100%;
    }
    .invoice-address-block, .invoice-address-form{
        margin:20px auto;
       
    }
    .payment-metod{
        width:100%;
    }
    .tarjeta, .paypal, .bizum, .transfer{
        width:85%;
    }

    .condiciones-compra{ 
        margin-left:10px;
    }

}
