.contenedor-registrar-direccion{
    width:1200px;
    margin:150px auto;
    font-family: 'Prompt', sans-serif;
}
.contenido-registration{
    display: flex;
    flex-direction: column;
    gap:20px;
    width:50%;
    margin:0 auto;
}
.breadcrumb-envio{
    display: none;
    /* display: flex; */
    align-items: center;
    gap:20px;
    width:100%;
    justify-content: center;
    
}
.envio{
    border:1px solid rgb(186, 184, 184);
    color:rgb(186, 184, 184);
    border-radius: 100%;
    padding:10px;
    width: 15px;
    height:15px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.envio:first-of-type{
    border:1px solid orange;
    background-color: orange;
    color:black;
}

.breadcrumb-envio>span:last-of-type{
    color:rgb(186, 184, 184);
}
.guion{
   height:1px;
   background: black;
   width:150px;
}

.contenido-registration>p{
    letter-spacing: 0.5px;
}
.contenido-registration>p span{
    color:orange;
    text-decoration:underline;
    cursor: pointer;
}
.formulario-payment{
    display: flex;
    flex-direction: column;
    width:100%;
}
.shipping{
    display: flex;
    flex-direction: column;
    gap:20px;
    /* width:100%; */
}
.shipping>h2{
    margin:0 auto;
    padding-bottom: 10px;
}
.formShipping{
    width:1170px;
    display:flex;
    flex-direction: column;
    gap:20px;
}

#email, #name, #surname,#documento, #company, #address, #phone, #select-country, #select-region, #city, #postal_code{
    padding:10px; 
    font-size: 1rem;
    border-radius: 0.5rem;
    border-width:1px;
    letter-spacing: 1px;
    /* width:100%; */
}
#select-country, #select-region{
    width:100%;
}
.formShipping .info-person,.formShipping .city,.formShipping .country-region{
    display: flex;
    align-items: center;
    justify-content:space-between;
    gap:30px;
    width:100%;
}
#name, #surname,#city, #postal_code  {
    width:50%;
}

.shipping-method{
    width:100%;
}
.shipping-method>ul{
    border:1px solid black;
    padding:20px 10px;
    list-style: disc;
    
}
.shipping-method>ul>li{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.type{
    display: flex;
    flex-direction: column;
}
/*---------Form ---Metodo del Pago Page----------------*/
.formShipping-other-address{
    width:30%;
    display:flex;
    flex-direction: column;
    /* gap:20px; */
    margin:0 auto;
} 

.formShipping-other-address .info-person,.formShipping-other-address  .city, .formShipping-other-address .country-region{
   display: flex;
   flex-direction: column;
   width:100%;
   margin:0;
   align-items:initial;
   /* gap:30px; */
}

.formShipping-other-address .country-region {
    flex-direction: row;
    width:372px;
}
.formShipping-other-address #email,
.formShipping-other-address #name,
.formShipping-other-address #surname,
.formShipping-other-address #city, 
.formShipping-other-address #postal_code,
.formShipping-other-address #phone, 
.formShipping-other-address #documento,
.formShipping-other-address #company,
.formShipping-other-address #address {
    width:350px;
    margin:0;
    font-size: 1rem;
}
/* .formShipping-other-address #address , .formShipping-other-address #phone {
    width: 95%;
} */
.formShipping-other-address #select-country, .formShipping-other-address #select-region{
    font-size: 0.8rem;
}
.payment-registration button:last-of-type{
    width:250px;
    align-self: center;
    margin-top:30px;
}
/*----------------------
.btn-next{
    width:250px;
    padding:10px;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    border:1px solid transparent;
    background-color: orange;
    border-radius:15px;
    margin-top: 20px;
    cursor: pointer;
}
.btn-next a{
    color:black;
}
*/
























