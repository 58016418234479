.button-link {
    /* text-transform: uppercase; */
    font-weight: 600;
    width: 188px;
    padding: 10px;
    font-size: 0.9rem;
    letter-spacing: 1px;
    text-align: center;
    cursor:pointer;
    border:1px;
    border-radius: 8px;
    line-height: 20px;
}

.cart-icon {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
}