.home-page{
    margin:20px auto;
    width:1170px;
}
.sectionHome{
    width:760px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:0 auto 36px;
}
.title-homepage{
    width:100%;
    color:#10351f;
    font-size: 28px;
    text-decoration: underline;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #fdead8;
    padding-bottom: 30px;
    font-family: "Ubuntu",Tahoma,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 500;
    line-height: 1.1;
    
}
.productos-homepage{
    border-bottom: 1px solid #fdead8;
    padding-bottom: 20px;
}
.info-hort{
    margin:55px auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:1000px;
    padding-top: 30px;
    background-color: rgb(255, 145, 35, 0.14);
    padding:20px
}
.text-alba{
    display:flex;
    gap:80px;
}
.text-intro{
    width:60%;
}
.intro>h2{
        font-size: 30px;
        font-weight: 500;
        line-height: 1.1;
}
.intro p{
    font-size:14px;
    line-height:20px;
    text-align: justify;
    margin-top: 0;
}
.link-to>a{
    color:#dd4814;
}
.iframe-homepage{
    margin:40px;
}

