.contenedor-mi-cuenta{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 1080px;
    margin: 50px auto 100px;
    font-family: 'Prompt', sans-serif;
}
.header-mi-cuenta{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 97%;
    padding-top:40px;
}
.welcome {
    text-transform: capitalize;
     margin:0 auto; 
    padding:0 0 20px;
    width:97%;
    text-align: center;
}
.button-micuenta{
    width:97%;
}
.button-micuenta div{
    padding:10px 0;
}
.button-micuenta div>button {
    padding:10px 10px;
    font-size: 20px;
    background-color: #fff9f3;
}
.button-micuenta div>button>span{
    font-size: 16px;
    letter-spacing: 0;
    display: flex;
    gap:8px;
}
.contenido-mi-cuenta>p{
    font-size: 20px;
    cursor:pointer;
    font-weight: 400;
}

.container-mi-cuenta{
    display: flex;
    flex-wrap: wrap;
    gap:27px;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 40px;
    margin:0 auto;
}
.componentes-mi-cuenta{
    border: 1px solid #dd4814;
    width:290px;
    /* padding: 20px; */
    display: flex;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
}
.componentes-mi-cuenta>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.componentes-mi-cuenta:last-of-type>div{
    margin-top:25px;
}
.img-mi-cuenta{
    width:20%;
}

.items-mi-cuenta{
    padding-left: 20px;
}
.componentes-mi-cuenta h2{
    cursor: pointer;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color:#EA7525;
    width:100%;
    margin:0;
    text-align: center;
    color: white;
    font-weight: 400;
}
