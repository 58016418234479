.panel{
    /* border:1px solid #dd4814; */
    width:1000px;
    margin:20px auto;
    /* border-radius: 4px; */
    font-family: "Ubuntu", Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.panel-primary{
    border:1px solid #e3e3e3;
    margin:20px ;
    border-radius: 4px;
    background-color: #f5f5f5;
    min-height: 20px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.42857143;
}
 .panel-primary>ul{
    list-style-type: disc;
 }
 .panel-primary>ul>li>a {
    color:#dd4814;
    text-decoration: none;
 }
 .panel-secondary{
    margin:20px;
    font-size: 14px;
     border:1px solid #dd4814;
     border-radius: 4px;
     padding: 10px 20px;
 }
 .panel-secondary>h2{
    font-weight: 500;
    line-height: 1.1;
    font-size: 33px;
    letter-spacing: 2px;
 }
 .panel-secondary>div{
    text-align: justify;
 }
 .panel-secondary>div a{
    color:#dd4814;
 }
 .panel-secondary>div a:hover{
    color:#97310e;
    text-decoration: underline;
 }

 .panel-secondary > div > div{
    display: flex;
 } 
 .panel-secondary>div>div>div:first-of-type{
    width:55%;
    display: flex;
    flex-direction: column;
 }
 .panel-secondary>div>div>div:last-of-type{
    width: 45%;
    text-align: center;
 }
 .panel-secondary ul{
    list-style-type: disc;
 }
 img{
    border:0;
 }
 /*----------Pagina CLIMA--------------*/

 .panel-secondary > .clima > ul{
    list-style-type: disc;
 }
 .panel-secondary > .clima > div:first-of-type {
    width: 50%;
    display: inline-block;
    margin-right: 50px;
 }
 .panel-secondary > .clima > div:last-of-type{
    display: inline;
 }

  /*----------Pagina ACERCA DEL PEDIDO--------------*/

  .panel-secondary>.supedido>div>div:first-of-type{
    width:initial;
  }
  .panel-secondary>.supedido>div>div:first-of-type>p{
    margin-right:10px;
  }


  .panel-secondary>.supedido>div>div:last-of-type{
    text-align: justify;
  }
   .panel-secondary>.clasificacion-limpieza>div:first-of-type, .panel-secondary>.embalaje-transporte>div:first-of-type, .panel-secondary>.recoleccion>div:first-of-type{
    gap:20px;
   }

   .panel-secondary>.clasificacion-limpieza>div>div:first-of-type, .panel-secondary>.embalaje-transporte>div>div:first-of-type, .panel-secondary>.recoleccion>div>div:first-of-type{
    width:initial;
  }
   .panel-secondary>.clasificacion-limpieza>div>div:last-of-type, .panel-secondary>.embalaje-transporte>div>div:last-of-type, .panel-secondary>.recoleccion>div>div:last-of-type{
    text-align: justify;
    width:100%;
  }

  /*----------Pagina PREGUNTAS--------------*/

.panel-secondary>.preguntas>div, .panel-secondary>.preguntas-como-registrarse>div {
    flex-direction: column;
}
.panel-secondary>.preguntas-realizacion-pedidos>div{
    flex-wrap: wrap;
}
.panel-secondary>.preguntas>div>div:first-of-type, .panel-secondary>.preguntas-como-registrarse>div>div:first-of-type  {
    width:initial;
}
.panel-secondary>.preguntas>div>div:last-of-type, .panel-secondary>.preguntas-como-registrarse>div>div:last-of-type{
    align-self: center;
}
.panel-secondary>.preguntas-formas-pago>div>div:last-of-type, .panel-secondary>.preguntas-problemas-pedido>div>div:last-of-type, .panel-secondary>.preguntas-sobre-nuestros-productos>div>div:last-of-type {
    width:initial;
    text-align: justify;
    align-self: flex-start;
}
.panel-secondary>.preguntas-formas-pago>div>div:last-of-type>p:last-of-type{
    align-self: center;
}
.panel-secondary>.preguntas-realizacion-pedidos>div{
    justify-content: space-between;
}
.panel-secondary>.preguntas-realizacion-pedidos>div>div:first-of-type{
    width:80%;
}
.panel-secondary>.preguntas-realizacion-pedidos>div>div:last-of-type{
  width:initial;
  margin:0 auto;
}

.panel-secondary>.enlaces{
    width:initial;
}






 
 



