.main_contacto{
    width:1000px; 
    margin: 50px auto 70px;
    border-radius: 3px;
    /* background: url("../../assets/images/cafe1-contacto.png") left center no-repeat, url("../../assets/images/cafe2_contacto.png") right center no-repeat, #B97B45; */
    background-color: rgba(252, 137, 23, 0.1);  
}

.formulario_contacto {
    height: 669px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:80%;
    margin:0 auto;
}

.tit_form {
    font-family: arial;
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    /* color: white; */
    margin:0 auto;
    width:340px;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-family: 'Prompt', sans-serif;
    text-align: center;
    width:100%;
}
.formularioContact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#nombre,
#mail,
#tlfn,
#mensaje_form {
    width: 98%;
    padding: 0.7rem;
    margin: 0.6rem auto;
    border: 0;
    border-radius: 0.5rem;
    font-family: arial;
    font-size: 1rem;
    color: lightslategray;
    letter-spacing: 0.1rem;
}

#mensaje_form {
    height: 116px;
}

.formularioContact>span{
    align-self: center;
    margin-top:30px;
}
#btn_enviar {
    padding: 10px 30px;
    text-align: center;
    font-size: 1.2rem;
    font-family: arial;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    background-color: transparent;
    border: 3px solid #EA7525;
    color: #EA7525;
    display: block;
    margin: 1.9rem auto;
    font-weight: 600;
    cursor: pointer;
}

#btn_enviar:hover{
    opacity:0.6;
}
/*--------------Vista 1024px-1200px--------------*/
@media screen and (max-width:1200px){
    .main_contacto{
        width:max(800px, 80%);
        margin-top:100px;
    } 
}

@media screen and (max-width:1024px){
    .main_contacto{
        width:max(600px, 80%);
        background-color:#EA7525;
    }
    .tit_form{
        font-size: 1.5rem;
    }
#nombre,
#mail,
#tlfn,
#mensaje_form {
    font-size: 0.9rem;
    width:80%;
    }
#btn_enviar {
    font-size: 1rem;
}
}
@media screen and (max-width:768px){
    .main_contacto{
        width:max(330px, 80%);
    } 
    }