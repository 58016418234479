.category-page{
    width:1000px;
    margin:0 auto;
    display:flex;
    flex-direction: column;
    gap:30px;
    justify-content: center;
    align-items: center;
}
.category-page>.contenido-category{
    display:flex;
    gap:30px;
    flex-direction: row;
}
.contenido-category>section{
    width:50%;
}
.img-product{
    width:100%;
}

.description-product{
    text-align: justify;
    font-size: 14px;
    padding-left: 20px;
}
.description-product>div{
    display:flex;
    flex-direction: column;
    width:100%;
}
/* .review-stars{
    align-self: center; 
    text-align: center;
} */
.image-container{
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 20px;
}

.description-product>h1{
    margin:15px 0 15px;
    font-size:36px;
    font-family: "Ubuntu",Tahoma,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    padding-bottom: 20px;
    border-bottom:1px solid #fdead8 ;
    margin-bottom: 20px;
    }
    .description-product>p{
            font-family: "Ubuntu",Tahoma,"Helvetica Neue",Helvetica,Arial,sans-serif;
            line-height: 1.42857143;
    }
    .tipo-compra{
        display: flex;
        flex-direction: row;
        gap:70px;
        margin-top:15px;
        border-top: 1px solid #fdead8;
    }

    .info-precio{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .info-precio>h3{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .precio-producto{
        font-size: 18px;
        padding-bottom: 0;
        margin: 0;
        color:#dd4814;
    }
    .entrega-pedido{
         /* color:#dd4814; */
        border:1px solid #c9c6c6;
        margin:10px 0;
        padding: 4px 6px;
        border-radius: 3px;
        background-color: #faf9f9;
    }
  .description-product>.btn-add-to-cart{
        padding-left: 0;
        text-align: center;
        margin-top:20px;
     
    }
   
/*------Reviews---------------*/
.info-Review{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}
.info-Review>.review-stars{
    align-self: center;
    margin-bottom:30px;
}

    .review{
        color:#dd4814 ;
        font-size: 16px;
    }
    .review:hover{
        cursor: pointer;
        text-decoration: underline;
    }
    .action-box{
        padding: 10px;
        font-family: Tahoma;
        width: 100%;
        border: 1px solid #bbb;
        margin-top: 20px;
        font-family: Tahoma;
        /* font-size: 1.1rem; */
    }
    #review-form{
        margin-top:0;
    }
    .alert {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: #fcf8e3;
        border-color: #fbeed5;
        color: #c09853;
    }
    .alert>p{
        margin:0;
    }
    .haz-click, .form-group>a{
        color:#dd4814;
        cursor: pointer;
    }
    .haz-click:hover, .form-group>a:hover{
        color:#97310e;
        text-decoration: underline;
    }
    .form-group{
        display:flex;
        flex-direction: column;
        width:100%;
        margin:0;
        margin-bottom: 10px;
    }
    .form-control{
            width: 98%;
            margin-bottom:20px;
            height: 38px;
            padding-left: 10px;
            line-height: 1.42857143;
            color: #333333;
            background-color: #ffffff;
            background-image: none;
            border: 1px solid #cccccc;
            border-radius: 4px;
            font-size: 1rem;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
            -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        }
        #review-form>label{
            display: inline-block;
            max-width: 100%;
            font-weight: bold;
            margin: 10px 0;
        }
        .clasification{
            text-align: center;
            margin-top:0;
        }
        .clasification input{
           display:none;
        }
        .clasification label{
            font-size: 45px;
            color:grey;
            display: inline-block;
            cursor: pointer;
        }
        .clasification input:checked+label{
            color: orange;
        }
        .seccion-comentarios{
            padding-left: 40px;
            padding-top: 20px;
            background-color: rgba(176, 102, 19, 0.15);
        }
        .seccion-comentarios label{
            padding-bottom: 10px;
            font-weight: bold;
            font-size: 1rem;
        }
        .seccion-comentarios .form-group * {
            width: 90%;
        }
        .seccion-comentarios .form-group:first-of-type{
            width:60%;
        }
        .seccion-comentarios .form-group textarea {
            padding: 10px;
            height: 100px;
        }
        .seccion-comentarios .form-group input[type="text"]{
            padding: 10px;
        }
        .btn-add-review{
            text-align: center;
        }
    
    




