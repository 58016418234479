.form-registrar{
    border: 2px solid #dd4814;
    width: 350px;
    margin: 135px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(174, 172, 171, 0.4); */
    border-radius: 20px;
    /* padding: 30px 30px 50px;*/
    padding:0;
} 
.form-registrar>h2{
    font-size: 3rem;
    font-weight: 100;
    /* color: rgb(54, 54, 54); */
    color:white;
    font-family: 'Open Sans', sans-serif;
    padding:  20px 0; 
    margin:0;
    text-shadow: 1px 1px 2px grey, 1px 0.5px 1px rgb(196, 192, 192);
    border: 1px solid #dd4814;
    width:100%;
    background-color: #dd4814;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;
}

.form-registrar a{
    color:black;
}
.form-registrar-items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
}
.formulario-registrar{
    /* background-color: rgba(242, 129, 97, 0.9);
    border-color: rgb(245, 147, 98);
    border-radius: 10px; */
    padding: 40px;
    width:250px;
    gap: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin:0;
}
.field-form{
    display: flex;
    align-items: center;
    gap:15px;
}
.field-form label{
    height: 22px;
}

.field-form input{
    height: 22px;
    border-radius: 4px;
    padding: 5px 8px;
    border: #f0f0f0;
    width: 200px;
    font-size: 1rem;
    background-color: #f5f5f5;
}

.field-form {
    position: relative;
}
.field-form:last-of-type{
    margin-bottom:30px;
}
#register-form>button{
    width:150px;
}

.btn-form{
    /* width: 120px; */
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    color:rgba(251, 83, 36, 0.9);
    letter-spacing: 1px;
    margin-top:20px;
    cursor: pointer;
}
.btn-form:hover{
    background-color: rgb(203, 200, 200);
    color:rgb(61, 60, 60);
}
.btn-form:disabled, .btn-form[disabled]{
    background-color: rgb(203, 200, 200);
    color:rgb(61, 60, 60);
    cursor:not-allowed;
    margin-top:40px;
}

.position-absolute1-pwd-icon {
    position: absolute;
    bottom: 5px;
    right:9px;
}

.login-redes{
    display: flex;
    flex-direction: column;
    /* background-color: white; 
    border-bottom-right-radius: 10px;
    border-top-right-radius:10px; */
    padding:16px 0 10px;
    width:250px;
    gap:20px;
}

.login-redes p a{
    color:rgb(13, 13, 13);
    font-size: 1rem;
    display: flex;
    gap:10px;
    font-family: 'Open Sans', sans-serif;
    align-items: center;
    border-radius: 6px;
}
.login-redes p a .icon-facebook{
    border-right: 1.3px solid rgb(7, 71, 233);
    padding:8px 10px;
}
.login-redes p a .icon-gmail{
    border-right: 1.3px solid #a51717;
    padding:8px 10px;
}
.red-facebook{
    background-color: rgb(99, 141, 248);
    font-weight: 600;
}
.red-facebook:hover{
    background-color: rgb(99, 141, 248, 0.7);
}
.red-gmail{
    background-color: #fc2d2d;
    font-weight: 600;
}
.red-gmail:hover{
    background-color: rgb(252, 45, 45, 0.7);
}

.icon-user1:before, .icon-locked:before{
    color:rgb(26, 25, 25);
}

.error{
    color:red;
    border:1px solid white;
    background-color: white;
    padding: 8px 10px;
    width:285px;
    position: absolute;
    top:1000px;
    border-radius: 5px;
    text-align: center;
}
.newsletter{
    align-self: flex-start;
    padding-left: 15px;
    font-family: 'Open Sans', sans-serif;
}
/*--------------Vista 1024px-1200px--------------*/
@media screen and (max-width:1200px){
    .form-registrar{
        width:750px;
        margin: 70px auto;
    }
}
/*--------------Vista Tablet--------------*/
@media screen and (max-width:1024px){
.form-registrar{
    width:max(400px,60%);
    padding:0 0 30px;
}
.form-registrar-items{
    flex-direction: column;
    align-items: center;
}
.form-registrar>h2{
    font-size: 2rem;
    padding-bottom: 10px;
}
.formulario-registrar{
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    gap:23px;
    padding-bottom: 20px;
}
.btn-form{
    margin-top:10px;
}
.login-redes{
    padding-top:25px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px ;

}
}
/*--------------Vista Movil--------------*/
@media screen and (max-width: 768px){
    .form-registrar{
        width:max(350px, 80%);
    }
    .form-registrar>h2{
        font-size: 1.2rem;
    }
    .form-registrar-items{
        width:max(340px, 70%);
    }
    .form-registrar>h2{
        font-size: 1.5rem;
    }
    .field-form input,.btn-form {
        font-size: 0.9rem;
    }
    .login-redes p a{
        font-size: 0.9rem;
    } 
    .login-redes{
        padding:12px 40px 30px;
        background-color:white;
       
    }
}


