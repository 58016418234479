.contenedor-direcciones{
    margin:90px auto;
    width:1000px;
    display: flex;
    flex-direction: column;
}
.contenido-direcciones{
    display:flex;
    flex-direction: row;
    gap:60px;
    align-items: center;
    justify-content: space-between;
}
.mis-direcciones{
    display:flex;
    flex-wrap: wrap;
    gap:50px;
    align-self: flex-start;
    justify-content: center;
    padding:20px 0 0 60px
}
.mis-direcciones article{
    border: 1px solid #EA7525;
    /* padding:20px;  */
    width:180px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    margin:0;
}
.mis-direcciones article>p{
    margin-left: 10px;
}
.mis-direcciones article>p:first-of-type {
    padding:4px 15px; 
    margin:0;
    /* border:1px solid  #003317; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width:84%; 
    background-color:#EA7525;
    text-align: center;
    color:white;
}
.mis-direcciones article>.delete{
    align-self: flex-end;
}
.btn_addAddress{
    margin:40px 0;
}
.volver-mi-cuenta{
    background-color: #fff9f3;
    border:none;
    text-transform: lowercase;
    align-self: flex-start;
    padding-top: 30px;
   color:rgb(120, 119, 119);
}
.volver-mi-cuenta span{
    text-transform: capitalize;
}

