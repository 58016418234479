.footer{
     width:100%; 
    margin-top: 40px;
    margin:50px auto 0;
    border-top: 1px solid #EA7525;
}
.footer .container {
    padding-left: 0px;
    padding-right: 0px;
    font-family: Tahoma;
    /* background-color: antiquewhite; */
    font-weight: bolder;
    margin:0 auto;
    display:flex;
    justify-content: center;
    padding-top:20px;
}
.social, .mas-producto, .enlaces, .otra-info{
    width:15%;
}
.footer-productos{
    padding:0;
    font-style: normal;
    width:12%;

}
.productos, .informacion{
    width:14%;
}

.social{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.productos{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.container h4{
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    font-size: 14px;
    padding-left:20px;
}
.container a {
    color: #EA7525;
    background-color: transparent;
}
.column>ul{
    padding:0 20px;
    font-size: 10px;
    line-height: 22px;
    list-style: none outside none;
    font-family: Tahoma;

}
.copy-right>span{
    font-size:14px;
}
.column li>a>img{
    vertical-align: middle;
}
.redes-footer{
    display: flex;
}