.form-login{
    border: 3px solid #dd4814;
    width: 1170px; 
    margin: 100px auto;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    /* background-color: #74CAD5; */
    border-radius: 20px;
    padding-bottom: 30px;
    gap:20px;
}
.form-login>h2{
    font-size: 1.5rem;
    font-weight: 100;
    color:white;
    font-family: 'Prompt', sans-serif;
    padding:15px 20px;
    margin:0;
    /* border:1px solid  #003317; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width:97%;
    background-color:#dd4814;
}
.contenido-form{
    display:flex;
    width:100%;
    justify-content: center;
    gap:60px;
}
.col-contenido{
    width:40%;
}
.login{
    border:1px solid #f0f0f0;
    border-radius: 8px;
}
.container-google{
   margin:10px 30px;
}

.form-login a{
    color:black;
}
.formulario{
gap: 20px;
display: flex;
flex-direction: column;
/* margin-top: 30px; */
margin-top: 0;
}
.field-form{
    display: flex;
    align-items: center;
    gap:15px;
}
.field-form label{
    height: 22px;
}

#intro-user, #intro-password, #intro-email{
    height: 22px;
    border-radius: 4px;
    padding: 5px 8px;
    border: #f0f0f0;
    width: 376px;
    font-size: 1rem;
    background-color: #f5f5f5;
    color:rgb(93, 92, 92);
    margin: 10px auto;

}
.field-form {
    position: relative;
    padding: 0;
}
 .position-absolute-pwd-icon {
    position: absolute;
    bottom: 14px;
    right:9px;
}
.formulario>button{
    margin-top:20px;

}
.field-form:last-of-type{
    align-self: center;
}

/* .btn-form{
    width: 120px;
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    color:rgba(251, 83, 36, 0.9);
    letter-spacing: 1px;
    margin-top:20px;
    cursor: pointer;
} */
button:hover{
    opacity:0.7;
}
/* .btn-form:hover{
    background-color: rgb(203, 200, 200);
    color:rgb(61, 60, 60);
} */
.btn-form:disabled, .btn-form[disabled]{
    background-color: rgb(203, 200, 200);
    color:rgb(61, 60, 60);
    cursor:not-allowed;
}
.forgot-password{
    font-size: 0.9rem;
    cursor: pointer;
    text-align: center;
}
.forgot-password:hover, .registrar>a:hover{
    color:rgb(0, 0, 0, 0.8);
}
.login-successfully{
    position: absolute;
    border:1px solid black;
    background-color: aqua;
}
.error-message{
        border: 1px solid red;
        background-color: red;
        color: white;
        padding: 11px 30px;
        width: 250px;
        border-radius: 8px;
        margin: 20px 0 0;
}
.registrar{
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    border-radius: 8px;
}
 .acceder, .registrar{
    border: 1px solid #f0f0f0;
    background-color: #f5f5f5;
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 20px;
    /* width: 95%; */
    margin: 0;
    text-align: justify;
}
.acceder{
    font-weight: bold;
}
.acceder:first-of-type{
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.registrar>h2 span{
    color:#dd4814;
}
/*--------------Vista 1024px-1200px--------------*/
@media screen and (max-width:1199px){
    .form-login{
        gap:10px;
        margin:70px auto;
    }
    .form-login>h2{
        font-size: 2rem;
    } 
    .registrar>a{
        font-size: 1rem;
    }
}
@media screen and (max-width:768px){
    .form-login{
        max-width: 80%;
       
    }
    .registrar>a{
        font-size: 0.9rem;
    }
}



