@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.slider-container {
    max-width: 100%;
}
/*  .slick-track {
        max-width:750% !important;
        display: flex;
        gap:30px;
        justify-content: center;
}  */
 .producto {
    max-width: 300px;
    color: black;
    font-size: 1rem;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align:center;
    cursor: pointer;
} 
.img-carusel{
    width:125px;
    display: block;
}
.slick-slide img{
    width:100%;
}
.producto  h3 {
    font-size: 0.8rem;

}
.slick-arrow::before {
    color:orange;
}

	.slick-list {
		width: 100%;
		min-width: 100%;
	}

    .slick-track {
        display: flex !important;
        justify-content: center;
    }
    .slick-slide {
        width: 250px !important;
    }
    .slick-slide>div {
        display: flex;
        justify-content: center;
    }
 /*    .slick-slide {
        width: 250px !important;
    }  */

/*--------------Vista 1024px-1200px--------------*/
@media screen and (max-width:1024px){
    /* .slick-track {
        max-width:1200% !important;
    } */
    .producto {
        max-width: 150px;
    }
    .img-carusel{
        width:100px;
}
    .slider-container {
    max-width: 700px;
}
   }

   @media screen and (max-width:768px){
    .slider-container {
        width:350px;
    }
    /* .slick-track {
        max-width:1300% !important;
   } */
   }