.contenedor-intranet-pedidos{
    width:1000px;
    margin:90px auto;
    display: flex;
    justify-content: flex-start;
    /* align-items:  center; */
    flex-direction: column;
    font-family: 'Prompt', sans-serif;
}
.contenedor-intranet-pedidos>h2{
    align-self: flex-start;
    /* padding-left: 60px; */
    /* color:rgb(106, 104, 104); */
}

.filterPedidos{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    font-size: 1rem;
    margin-bottom: 45px;
}
.filterPedidos>p{
    padding-right: 15px;
}
#filter_date{
    font-size: 1rem;
    border-radius: 4px;
    padding:5px 8px;
}



.pedidos{
   /*  display:flex;
    flex-wrap:wrap;
    width:90%;
    font-family: 'Prompt', sans-serif;
    margin-bottom: 20px;   
    justify-content: center;
    gap:25px; */
    display:flex;
    flex-wrap: wrap;
    gap:70px;
    align-self: flex-start;
    /* justify-content: center; */
    padding:0;
    width:100%;
}
.pedidos article{
    border: 1px solid #EA7525;
    padding:0;
    width:250px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pedidos-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    width:100%;
    padding-top: 30px;
}

.pedidos-empty p {
    font-size: 1.3rem;
}

.info-pedido-intranet{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width:100%;
}

.info-pedido-intranet h4{
     text-align: center; 
    font-size: 1rem;
    font-weight: 100;
    color:white;
    font-family: 'Prompt', sans-serif;
     padding:4px 15px; 
    margin:0;
    /* border:1px solid  #003317; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    /* width:97%; */
    background-color:#EA7525;
}
.info-pedido-intranet p{
    margin:0;
    padding: 10px 20px;
}
.info-pedido-intranet>div{
    display: flex;
    flex-direction: row;
}
.info-pedido-intranet>div:first-of-type{
    justify-content: space-between;
}

.info-pedido-intranet>div>div:last-of-type{
  padding-left: 5px;
}
.info-pedido-intranet>div:nth-of-type(2)>span:last-of-type,.info-pedido-intranet>div:nth-of-type(3)>span:last-of-type{
    padding-left: 5px;
  }
.address-pedido-intranet{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.arrow{
    padding-left: 10px;
    cursor: pointer;
    text-align: center;
}
.arrow img{
    width:15px;
}
.arrow-up img {
   transform: rotate(180deg);
}
.info-pedido-intranet>div:nth-of-type(3),.info-pedido-intranet>div:nth-of-type(4){
    position: relative;
}
.factura-pedidos-intranet{
    display:flex;
    justify-content: flex-start;
}
.show-address,.show-factura  {
    display:block;
    /* position: absolute; */
    border: 1px solid rgb(200, 195, 195, 0.5);
    text-decoration: none;
    border-radius: 5px;
    padding: 20px;
    margin-top: 50px;
    background-color: white;
    width:170px;
    top:10px;
}
.show-address{    
    left:-20px;   
}
.show-factura {
    left:151px; 
}
.show-address:before,.show-factura:before {
    content: '';
    display: block;  
    position: absolute;
    left: 100px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: rgb(200, 195, 195, 0.5);
} 
.show-address:after, .show-factura:after {
    content: '';
    display: block;  
    position: absolute;
    left: 101px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
}
.show-address>p{
    margin:0;
    padding:0;
}

.hide-address, .hide-factura{
    display: none;
}

.btn-detalle{
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   gap:30px;
   margin-top:25px;
   margin-bottom: 25px;
}
.info-pedido-intranet>div:last-of-type>span:first-of-type{
    align-self: flex-end;
}
.info-pedido-intranet>div:last-of-type>span:last-of-type{
    display: flex;
    gap:20px;
    /* color:rgb(106, 105, 105); */
    
}
.ver-producto{
    cursor:pointer;
}
.pedido-intranet{
    display: flex;
    padding:40px 20px 20px;
    justify-content: space-between;

}
.pedido-intranet>div:first-of-type{
    width:55%;
}

.info-producto-intranet{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
     width:90%; 
    border: 1px solid rgb(179, 175, 175);
    padding: 10px 15px 15px;
    border-radius: 8px;
}
.info-producto-intranet>picture{
    width:120px;
}
.info-producto-intranet>picture>img{
    max-width: 100%;
    height: auto;
}
.intranet-producto{
    display: flex;
    flex-direction: column;
    gap:20px;
    font-size: 1rem;
    width:80%;
}
.buttons-producto-intranet{
    display: flex;
    flex-direction: column;
    gap:20px;
   padding-top: 50px;
}