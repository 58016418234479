
.contenido-section{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-start;
   
}
.first-section{
    border-bottom: 1px solid #fdead8;
    margin:30px 20px 10px;
}
.section-comp{
    width:30%;
}
.section-comp:first-of-type{
    width:30%;
}
.image-article{
    padding-top: 100px;
    justify-self: center;
}
.second-section{
    gap:50px;
    width:100%;
  justify-content: center;
}
.third-section{
    margin: 20px auto;
    width:70%; 
    justify-content: center;
    align-items: center;
   
}
.title-section-comp{
    font-weight: 600;
    padding: 30px 0 20px;
}
.title-section-comp>span{
    color:#EA7525;
    border-radius: 100%;
    border: 1px solid #EA7525;
    padding:10px 15px;
    margin-right: 10px;
}

.bandeja, .variedades-bandeja, .cantidad-bandeja{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    gap:5px;
}

.set-caja-articulo, .caja-combinacion {
    align-items: center;
    padding: 0 24px;
    border: 1px solid #fdead8;
    border-radius: 15px;

}

.set-caja-articulo {
    display: flex;
    justify-content: space-between;
}

.caja-combinacion {
    display: grid;
    grid-template-columns: 50% 40% 10%;
}

.precio-total {
    text-align: end;
    padding: 25px 25px 0 0 ;
    font-size: 1.2rem;
    font-weight: 600;
}
.caja-combinacion{
    height:49px;
}
.variedades-bandeja > article:nth-of-type(2n-1), .cantidad-bandeja>article:nth-of-type(2n-1) {
    background-color: #fdead8;
   
}
.cantidad-bandeja{
    border-bottom: 1px solid #fdead8;
    padding-bottom:20px;
    min-height: 170px;
}

.variedades-label, .bandeja-label{
    padding-left: 10px;
}
#cantidad1-bandeja, #cantidad2-bandeja, #cantidad3-bandeja, #cantidad4-bandeja, #cantidad5-bandeja{
    width:40px;
    margin-right: 10px;
}

.total-peso{
    color:#EA7525;
    text-align: center;
    font-size: 1.5rem;
}
.caja-completa{
    color:green;
}
.third-secction .img-product{
 width:100px;
}
.third-section>div{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.add-to-cart>a>button:disabled, .add-to-cart>a>button[disabled] {
    cursor:not-allowed;
}

/*---------------Vista Movil--------------*/
@media screen and (max-width:768px){
.container-caja-medida{
        width: max(80%,350px);
    }
.contenido-section{
    flex-direction: column;
    gap:10px;
    justify-content: center;
    width: max(80%,350px);
 
}
.first-section{
    margin-top:0;
}


.image-article{
    padding-top: 20px;
}
}


