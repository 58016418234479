.carusel-productos .container-list{
    width:100%; 
    height:100%; 
    gap: 30px;
    align-items: center;
    margin: 45px 10px 10px;
    flex-wrap: nowrap;
}

.carusel-productos .container-list .producto-details {
    min-height: 168px;
}
    
    .products {
        font-size: 0.9rem;
    }
    .products h2{
        margin-top:30px;
    }

    .carusel-productos .product-list img{
        width:200px;
    }
    .carusel-productos .product-price, .carusel-productos .product-name{
        font-size: 14px;
    }
    
