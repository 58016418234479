/* .volver-mi-cuenta{
    background-color: #fff9f3;
    border:none;
    text-transform: lowercase; 
    cursor: pointer;
    display:flex;
    gap:5px;
    /* align-self: center;
    margin-top:30px ;
}
.volver-mi-cuenta:hover{
    opacity:0.7;
    font-weight: 600;
}

.volver-mi-cuenta span{
    text-transform: capitalize;
    color: black;
}
.volver-mi-cuenta span:last-of-type{
    width:16px;
}
.volver-mi-cuenta span:last-of-type>img{
    width:100%;
} */
button {
    text-transform: uppercase;
    font-weight: 600;
    padding: 6px 10px;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-align: center;
    cursor:pointer;
    border:1px;
    border-radius: 8px;
}