.description-section{
    display:flex;
    align-items: center;
    gap:50px;
    border-bottom: 1px solid #fdead8;
    padding:0 0 30px;
}
.description-section>div>p{
    font-family: "Ubuntu",Tahoma,"Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 1.43;
} 