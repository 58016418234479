/* body{background:#fff9f3;font-family:Tahoma;}
.slide-image{width:100%;font-family:Tahoma;}
.carousel-holder{margin-bottom:30px;font-family:Tahoma;}
.carousel-control,
.item{border-radius:4px;font-family:Tahoma;}
.caption{height:130px;overflow:hidden;font-family:Tahoma;}
.thumbnail img{width:100%;font-family:Tahoma;}
.ratings{padding-right:10px;padding-left:10px;color:#d17581;font-family:Tahoma;}
.thumbnail{padding:0;font-family:Tahoma;}
.thumbnail .caption-full{padding:9px;color:#333;font-family:Tahoma;}
#menu .glyphicon{margin-right:10px;}
#menu .panel-body{padding:0px;}
#menu .panel-body table tr td{padding-left:15px}
#menu .panel-body .table{margin-bottom:0px;}
#login-nav input{margin-bottom:15px;}
#header{background:url(../../../public/bg5.png) no-repeat left bottom;background-repeat:no-repeat;width:100%;z-index:1000;top:0;position:absolute;font-family:Tahoma;}
#header.affix{top:-106px;position:fixed!important;font-family:Tahoma;}
#header.affix-bottom{top:-106px!important;position:fixed!important;font-family:Tahoma;}
#header-top{min-height:100px;font-family:Tahoma;}
#header .navbar{margin-bottom:0;border-radius:0px;font-family:Tahoma;}
#content{margin-top:178px;font-family:Tahoma;}
#header-top .title{padding:20px;padding-left:5px;font-family:Tahoma;}
#header-top .logo-img{margin-top:2px;width:116px;float:left;font-family:Tahoma;}
#header-top .logo-text{margin-left:10px;padding-top:10px;font-family:Tahoma;}
.header-button{margin:10px;margin-right:0;box-shadow:0px 0px 2px #000;border-radius:4px;font-family:Tahoma;}
.flags > button, .flags.open > button{background:none!important;border:0!important;font-family:Tahoma;}
.feature{margin-bottom:20px;font-family:Tahoma;}
.nav-pills{margin-top:20px;font-family:Tahoma;}
.calendar-item, .shop-item{text-align:center;padding:10px;font-family:Tahoma;}
.calendar-item h2{font-size:18px;margin-top:10px;font-family:Tahoma;}
.calendar-item span{font-size:18px;margin-bottom:10px;margin-top:10px;font-family:Tahoma;}
.calendar-item a{text-decoration: none;}
.navbar-default{background:#ff9123;background:-moz-linear-gradient(top, #ff9123 0%, #cf3700 100%);background:-webkit-gradient(linear, left top, left bottom, color-stop(0%,#ff9123), color-stop(100%,#cf3700));background:-webkit-linear-gradient(top, #ff9123 0%,#cf3700 100%);background:-o-linear-gradient(top, #ff9123 0%,#cf3700 100%);background:-ms-linear-gradient(top, #ff9123 0%,#cf3700 100%);background:linear-gradient(to bottom, #ff9123 0%,#cf3700 100%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9123', endColorstr='#cf3700',GradientType=0);font-family:Tahoma;}
.breadcrumb{background-color:#fdead8;font-family:Tahoma;font-size: 11px;}
.add-cart:focus, .add-cart:hover{text-decoration: none;}
.precio-producto{font-size: 32px;}
hr, .page-header{border-color:#fdead8;font-family:Tahoma;}
.side-collapse{display: none;}
.grid-productos{display: flex;}
.row.grid-productos{flex-wrap: wrap;}
.grid-productos.producto-individual{flex-direction: column;}
h5.dos-lineas{height: 2.2em; line-height: 1.1em;}
.datepicker table tr td.day:not(.disabled){color: #00a500;}
.breaking-word{word-break: break-all;}
@media screen and (max-width:768px){.header-button{margin-right:0px!important;font-family:Tahoma;}
#content{margin-top:52px;font-family:Tahoma;}
.side-collapse-container{width:100%;position:relative;left:0;transition:left .4s;font-family:Tahoma;}
.side-collapse-container.out{left:200px;font-family:Tahoma;}
.side-collapse{display:block;top:50px;bottom:0;left:0;width:200px;position:fixed;overflow:hidden;transition:width .4s;font-family:Tahoma;}
.side-collapse.in{width:0;font-family:Tahoma;}
.navbar-toggle.pull-left{margin-left:5px;font-family:Tahoma;}
.grid-productos{display: block;}
header .btn.pull-right{position:relative;margin-right:15px;padding:5px 11px;margin-top:9px;margin-bottom:8px;border-radius:4px;box-shadow:0px 0px 2px black;font-family:Tahoma;}
}
.hamburger{display: none;}
@media (min-width: 768px) and (max-width:990px){
    .hamburger{display: block;}
    #menu{display: none;}
    .product-header4{display: none !important;}
    .nav.navbar-nav.side-collapse{top: auto;position: inherit;}
    .side-collapse{display:block;top: 50px; position: absolute;}
    .side-collapse.in{display: none !important;}
}
.nav.navbar-nav.side-collapse{display: block;}
.logo-mobile{height:26px;margin-top:-2px;margin-left:-14px;font-family:Tahoma;}
.gallery .thumbnail{height:135px;overflow:hidden;border-radius:0;border:0;background:transparent;font-family:Tahoma;} */

.header{
    /* background:url(../../../public/bg5.png) no-repeat left bottom; */
    background-repeat:no-repeat;
    width:100%;
    top:0;
    font-family:Tahoma;
    z-index: 1000;
    /* height:177px; */
    height:190px;
}
.input-responsive, .label-responsive{
    display: none;
}
.container{
    width:1170px;
    margin:0 auto;
}
.row-header{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.logo{
    /* height:127px; */
    height:140px;
}

.col-logo{
    display:flex;
    flex-direction: row;
    width:50%;
    /* align-items: center; */
}
.col-logo a:last-of-type{
    align-self: center;
    padding-left: 10px;
}

.logo-header{
    margin-top: 2px;
    font-family: Tahoma;
}

.logo-text {
    margin-left: 10px;
    padding-top: 10px;
    font-family: Tahoma;
}
.col-intranet-redes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
/*-----Redes sociales-------*/
.col-redes{
    display:flex;
    gap:8px;
    padding-top: 10px;
    padding-right: 10px;
}
.btn-social-icon {
    display:flex;
    justify-content: center;
    align-items: center;
    height:34px;
    width:34px;
}
.btn-social-icon > * {
    width:100%;
    height:100%;
    font-size:34px;
}
/* .btn-facebook{
    background-image: linear-gradient( to bottom, transparent 20%, white 20%, white 93%, transparent 93% );
    background-size: 55%;
    background-position: 70% 0;
    background-repeat: no-repeat;
}
.btn-twitter{
    background-image: linear-gradient( to bottom, transparent 20%, white 20%, white 93%, transparent 93% );
    background-size: 55%;
    background-position: 55% 0;
    background-repeat: no-repeat;
} 

.btn-facebook svg path {
    fill:#3b5998;
}
.btn-twitter svg path {
    fill:#55acee;
}*/
.btn-phone {
 font-size: 20.5px;
 padding-top: 4px;

}

 /* .fa-facebook:before {
    content: "\f09a";
}
.fa-twitter:before {
    content: "\f099";
} */
/*-----Menu principal------------*/
.container .menu {
    background: #ff9123;
    background: -moz-linear-gradient(top, #ff9123 0%, #cf3700 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ff9123), color-stop(100%,#cf3700));
    background: -webkit-linear-gradient(top, #ff9123 0%,#cf3700 100%);
    background: -o-linear-gradient(top, #ff9123 0%,#cf3700 100%);
    background: -ms-linear-gradient(top, #ff9123 0%,#cf3700 100%);
    background: linear-gradient(to bottom, #ff9123 0%,#cf3700 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9123', endColorstr='#cf3700',GradientType=0);
    font-family: Tahoma;
    /* border-color: #bf3e11; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    height:48px;
    border:1px solid #97310e;
    text-transform: uppercase;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.menu-horizontal {
    height:100%;
    display:flex;
}
/* .navbar-collapse {
    display:flex;
} */
.nav-list {
    /* height:100%; */
    display: flex;
    flex-direction: row;
    gap:16px;
    margin:0 auto;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: space-between;
}

.nav-list > li {
    height: 100%;
    display: flex;
    align-content: center;
}

.nav-list > li a{
    color:white;
    padding: 12px 10px;
    letter-spacing: 1px;
    font-weight: 500;
    font-size:15px;
}
.nav-list >li:last-child {
    padding: 16px 10px;
    display: block;
    height: auto;;
}
.nav-list > li:hover {
    background-color:#ae3910;
    color: white;
}

/*Menu intranet */
.col-intranet {
    display: flex;
    flex-direction: row;
}

/*---------------Menu  Other Products-------- */
.dropdown {
    position: relative;
    cursor: pointer;
    color: #fff;
}

/*------------------Intranet----------------*/
.header-button{
    margin:10px;
    margin-right:0;
    /* box-shadow:0px 0px 2px #000; */
    border-radius:4px;
    font-family:Tahoma;
}
/* .pull-right {
    float: right !important;
} */
.col-intranet{
    display: flex;
}
.btn-group {
    position: relative;
    /* display: inline-block; */
    vertical-align: middle;
    display:flex;
    gap:10px;
}
.btn-group >a{
        color: #dd4814;
        text-decoration: none;
    }
.btn-sm, .btn-group-sm>.btn {
        padding: 5px 10px;
        font-size: 14px;
        line-height: 1.5;
        border-radius: 3px;
    }
.btn-primary {
        color: #ffffff;
        background-color: #EA7525;
        border-color: #EA7525;
        margin-right:5px;
     
    }
.btn_primary:hover .customer_nav {
        display: block;
    }
    
    button{
        text-transform: none;
    }
    .btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        user-select: none;
        overflow: visible; 
    } 
    .btn-success {
        color: #ffffff;
        background-color: #38b44a;
        border-color: #38b44a;
    }
    .avatar {
        height:auto;
        width:100%;
        cursor: pointer;
    }
    
    .avatar:hover .customer_nav {
        display: block;
    }
    
    .customer-name{
        color:#EA7525;
        width: 20px;
        height: 20px;
        line-height: 15px;
        border-radius: 50%;
        font-family: monospace;
        font-size: 1.3rem;
        border:1px solid #EA7525;
        position: relative;
        top:0;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    .customer_nav {
        display:none;
        position: absolute;
        left: 0;
        top:18px;
        padding: 0;
        border:1px solid rgb(221,72,20,0.5);
        background-color: #fff;
    }
    .customer_nav li{
        padding: 12px;
        font-size: 1rem;
        cursor: pointer;
        display: block;
        color: rgb(221,72,20,0.5);
    }

    /* ---------------Flags--------------*/
    
    .pull-right>button{
        padding: 8px 12px;
        font-size: 14px;
        line-height:1.42857143; 
        border-radius: 4px;
    }
    .btn > img{
        vertical-align: middle;
    }
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        color:white;
    }
    .flags > button, .flags.open>button {
        background: none!important;
        border: 0!important;
        font-family: Tahoma;
    }

    .btn-default:hover, .btn-default:focus, .open>.dropdown-toggle.btn-default {
        color: #ffffff;
    }
    
    .dropdown-toggle:focus {
        outline: 0;
    }
    .pull-right>.dropdown-menu {
        right: 0;
        left: auto;
        border-radius:4px;
    }
     .dropdown-menu {
        display:none;
    } 
    .dropdown-open{
        display:block;
        position: absolute;
        /* top: 100%; */
        top: 31px;
        left:0;
        z-index: 1000;
        float: left;
        min-width: 160px;
        padding:0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #EA7525;
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: 4px;
        margin-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    }
    .dropdown-open > li {
        margin-top: 2px; 
        background-color: #ffffff;
    }
    .dropdown-menu>li a {
        display: block;
        /* padding: 3px 20px; */
        clear: both;
        font-weight: normal;
        /* line-height: 1.42857143; */
        color: #333333;
        white-space: nowrap;
        height:100%;

    }
.dropdown-menu>li a:hover,.dropdown-menu>li a:focus {
    text-decoration: none;
    color: #ffffff;
    background-color: #EA7525;
 
    
}
.dropdown-menu>li>a>img{
    vertical-align: middle;
}
  /*--------------vista mobil----------------*/

@media screen and (max-width:768px){
   .header{
    width:min (370px,100%);
    margin:8px auto;
   }
 
   .row-header{
    justify-content: flex-start;
    gap:80px;
   }
  
   .col-logo{
    width:initial;
   }

   .logo-header>img{
    width:200px;
   }
   .btn-primary>span{
    display: none;
   }
   .menu-horizontal{
    margin-left:25px;
   }
 
  /* ul.nav-list{
    display:none;
}  */
.input-responsive:checked ~ .nav-list {
    display: flex;
    position: absolute;
    flex-direction: column;
    gap:0;
    width: 200px;
    top: 148px;
    height:160px;
    left:0px;
} 

.nav-list li>a{
    color:black;
}

/* .nav-list li:nth-of-type(3) {
    display: none;
} */
.label-responsive{
    box-sizing: border-box;
    display: flex;
    border: 1px solid rgba(71, 42, 175, 0.95);
    border-radius: 5px;
    width: 25px;
    height: 25px;
    text-align: center;
    user-select: none;
    font-weight: 600;
    flex-direction: column;
    align-items: center;
    gap:5px;
    justify-content: center;
    padding: 13px;
}

 .label-responsive .menu-hamburger{
    background-color: rgba(43, 32, 142, 0.95);
    border:1px solid rgba(24, 74, 183, 0.95);
    width:15px;
}

.nav-list .product-header{
    display: block;
    background: rgba(232, 231, 230, 0.95);
    margin: 0;
    padding: 10px;
    list-style: none;
    border-bottom: 1px solid rgba(238, 229, 227, 0.7);
    font-size: 0.85rem;
    height:33px;
    display: block;
    line-height: 33px;
    color: black;
    width:150px;
    
}
.nav-list .product-header:first-of-type{
    border-top:1px solid #d2d2d2;
} 

}
