.contendio-resumen{
    display: flex;
    flex-direction: column;
    align-items:center;
}
.sigue-comprando{
    width:104%;
    margin-bottom: 30px;
    align-self: center;
}

.sigue-comprando>button{
    width:100%;  
}
.ordered-shopping{
    font-family:'Prompt', sans-serif;
    border:1px solid rgb(209, 209, 209); 
    display:flex;
    flex-direction: column;
    width: 96%; 
    padding:10px 15px 30px;
}
.resumen-compras{
    display: flex;
    flex-direction: column;
}
.ordered-shopping>h2{
    font-size: 1.1rem;
    align-self: center;
    padding-bottom: 20px;
}

.ordered-shopping>.resumen-shipping>article , .ordered-shopping .shipping-price{
    display:none;
} 

.subtotal,.total,.shipping-sin-precio{
    display:flex;
    justify-content: space-between;
    padding-bottom: 20px;
    align-items: center;
}
.subtotal{
    border-bottom: 1px solid rgb(209, 209, 209);
    margin:30px 0 0;
}
.shipping-sin-precio{
    opacity:0.7;
}
.total span{
    font-weight: 600;
}
.total-impuestos{
    display: flex;
    flex-direction: column;
}
.total-impuestos span:last-of-type{
    font-weight: normal;
    opacity:0.7;
}
.order{
   /*  width:100%;
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 20px;
    background-color:rgb(244 183 121 / 90%);
    border: 1px solid rgb(244 183 121 / 90%);
    box-shadow:1px 1px 1px rgba(242, 217, 192), 1px 1px 1px rgba(218, 128, 38) ;
    color:rgb(105, 73, 61);
    cursor:pointer; */
    width:100%;
    padding:10px;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    border:1px solid transparent;
    background-color: orange;
    border-radius:15px;
    margin-top: 20px;
    cursor: pointer;
}
.order a{
    display: block;
    width: 100%;
    font-size: 1.1rem;
    color:rgb(61, 7, 7);
}
.order a:hover{
    opacity:0.7;
}
.ordered-shopping button:last-of-type{
   align-self: center;
}
/*-------------------Metodo del Pago Page ------------*/
.ordered-shopping-cost {
    background-color:  rgb(245, 241, 241);
    padding:20px;
    padding-bottom: 20px;
    height:auto;
    width:60%; 
    font-size: 0.8rem;
    display:flex;
    flex-direction: column;
    font-family:'Prompt', sans-serif;
    font-size: 1rem;
}
.ordered-shopping-cost .sigue-comprando{
    display: none;
}
.ordered-shopping-cost h2{
    text-align: center;
    font-size: 1.2rem;
    padding: 30px 0;
}
.ordered-shopping-cost .shipping-price, .ordered-shopping .resumen-shipping{
    display:flex;
}
.ordered-shopping-cost .resumen-shipping{
    min-height:400px;
    display: flex;
    flex-direction: column;
    gap:20px;
}
.ordered-shopping-cost .resumen-shipping>article
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ordered-shopping-cost .resumen-shipping>article picture{
    width:75px;
}
.ordered-shopping-cost .resumen-shipping>article picture>img{
    width:100%;
}
.ordered-shopping-cost .title-product{
    width:50%;
    justify-self: flex-start;
}
.ordered-shopping-cost .quantity input{
    background-color: rgb(245, 241, 241);
}
.ordered-shopping-cost .shipping-price{
    border-bottom: 1px solid rgb(209, 209, 209);
    gap:10px; 
    justify-content: space-between;
}
/* .ordered-shopping-cost button:last-of-type{
    display: none;
} */
.ordered-shopping-cost .shipping-sin-precio{
    display:none;
}
.ordered-shopping-cost .resumen-compras .button-link{
    display: none;
}


/*---------------Vista 1024px--------------*/
@media screen and (max-width:1200px){
    .ordered-shopping-cost{
        width:45%;
    }
.ordered-shopping-cost .resumen-shipping{
    height:80%;
}
.ordered-shopping-cost .title-product{
    width:40%;
}
.condiciones-compra{
    margin-left:50px;
}
}
/*---------------Vista Tablet--------------*/
@media screen and (max-width:1024px){
    .ordered-shopping{
        border: none;
        padding: 0;
    }
    .ordered-shopping>h2{
        display: none;
    }}

    @media screen and (max-width:768px){
    .ordered-shopping-cost {
        display: none;
    }
}