.container-datos{
    width:1000px;
    margin:90px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.header-datos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 70%;
    height: auto;
}
.mis-datos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 60px;
}
.datos-perfil{
    display:flex;
    align-items: center;
    justify-content:flex-start;
    margin:0 auto;
    width:800px;
    gap:30px;
    padding-bottom: 30px;
}
.datos-perfil h3{
    align-self: flex-start;
}
.datos-perfil label{
   width:220px; 
}
.datos-perfil input{
    padding:8px 0 8px 10px ;
    font-size: 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
}
.newsletter-datos{
    padding-left: 0;
}


@media screen and (max-width: 768px){

.container-datos{
    width:max(350px, 80%);
    margin:50px auto;
}
}