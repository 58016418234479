.App {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  /* background: #fff9f3; */
  width:1170px;
  margin: 0 auto;
}
.contenido-factura {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.contenido-factura .header,
.contenido-factura .footer {
  display: none;
}

/*----------------Fonts--------------*/
@import url('https://fonts.googleapis.com/css2?family=Allison&family=Open+Sans&family=Prompt&family=Roboto&display=swap');
/*font-family: 'Allison', cursive;
font-family: 'Prompt', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif;*/
/*--------------Icons-----------------*/ 
@font-face {
  font-family: 'icomoon';
  src:  url('assets/icons/fonts/icomoon.eot?n4sg0r');
  src:  url('assets/icons/fonts/icomoon.eot?n4sg0r#iefix') format('embedded-opentype'),
    url('assets/icons/fonts/icomoon.ttf?n4sg0r') format('truetype'),
    url('assets/icons/fonts/icomoon.woff?n4sg0r') format('woff'),
    url('assets/icons/fonts/icomoon.svg?n4sg0r#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-basket:before {
  content: "\e902";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}
.icon-shop:before {
  content: "\e902";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}
.icon-cart:before {
  content: "\e902";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}
.icon-ecommerce:before {
  content: "\e902";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}
.icon-buy:before {
  content: "\e902";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}
.icon-purchase:before {
  content: "\e902";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}

.icon-user:before {
  content: "\e901";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}

.icon-truck:before {
  content: "\e9b0";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}

.icon-minus:before {
  content: "\ea0b";
  color:#ba2e07cc;
  font-size: 5rem;
}
.icon-instagram:before {
  content: "\ea92";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}

.icon-facebook-with-circle:before {
  content: "\e900";
  color:rgb(0, 140, 255, 0.5);
  font-size: 22px;
}

.icon-gmail:before {
  content: "\e904";
  color: rgb(26, 25, 25);
  font-size: 22px;
}

.icon-user1:before {
  content: "\e971";
  font-size: 22px;
}

.icon-locked:before {
  content: "\e903";
  font-size: 22px;
}
.icon-mail3:before {
  content: "\ea85";
  color:rgb(26, 25, 25);
  font-size: 22px;
}
.icon-eye:before {
  content: "\e9ce";
  color:rgb(26, 25, 25)
}
.icon-eye-blocked:before {
  content: "\e9d1";
  color:rgb(26, 25, 25)
}
.icon-facebook:before {
  content: "\ea90";
  font-size: 22px;
  color:rgb(26, 25, 25)
}
.icon-menu:before {
  content: "\e9bd";
  font-size: 25px;
}
.icon-bin:before {
  content: "\e9ac";
}

a{
  text-decoration:none ;
}
ul{
  list-style: none;
}

.icon-basket:hover ,.icon-facebook-with-circle:hover , .icon-instagram:hover , .icon-truck:hover , .icon-purchase:hover , .icon-buy:hover , .icon-ecommerce:hover , .icon-cart:hover , .icon-shop:hover{
  opacity:0.6;
}   

/*--- Breadcrumbs ---*/

.breadcrumbs{
  width:1130px;
  background-color: #fdead8;
  padding:10px 20px;
  gap:0;
}
.breadcrumbs> *{
  display:inline-block;
}

.breadcrumbs>div>a, .breadcrumbs .linea{
  color: #dd4814;
}
.breadcrumbs .linea{
  padding:0 10px;
}
.breadcrumbs .crumbs:after{
  content:'>';
  margin-left:10px;
}

.breadcrumbs .crumb:last-child:after{
  display:none;
}

.breadcrumbs .crumb a{
  text-transform: capitalize;
  color: #dd4814;
}


