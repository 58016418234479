.shopping-total{
    font-family: 'Prompt', sans-serif;
    display: flex;
    flex-direction: column;
}
.shopping-total h2{
    letter-spacing: 2px;
}

.shopping>article{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem; 
    border-bottom: 1px solid rgb(209, 209, 209) ; 
    padding: 20px 0;
   
}
.shopping>article>picture{
    width:75px;
}
.shopping>article>picture>img{
    max-width: 100%;
    height: auto;
}
.shopping>article>span:first-of-type{
    width:65%;
    padding-left: 20px;
}

.quantity{
    display: flex;
    gap: 10px;
    border: 1px solid rgb(186, 184, 184);
    border-radius: 20px;
    padding: 0;
    align-items: center;
    margin:0 5px;
}
#quantities{
    width:25px;
    border: none;
    text-align: center;
}
.price-unit,.price-total{
    padding-right: 6px;
    
}
.btn-minus, .btn-plus{
    color:rgb(186, 184, 184);
    font-size: 2rem;
    cursor: pointer;
    border:none;
    background-color: transparent;
}
.delete{
    border: none;
    background-color: transparent;
}
.icon-bin{
    display:block;
    cursor:pointer;
    color:rgb(186, 184, 184);
    font-size: 1rem;
}
/*---------------Vista 1200px--------------*/
@media screen and (max-width:1200px){
    .quantity{
        gap:15px;
        padding:0 10px;
}
}
/*---------------Vista Movil--------------*/
@media screen and (max-width:768px){
    .shopping-total h2{
      text-align:center;
    }
    .shopping>article{
        display: grid;
        grid-template-areas: 'foto title title delete'
                              'foto price_u cant price_t';
        font-size: 1rem;
        gap:15px;
                             
    }
    .shopping>article>picture{
        grid-area: foto;
    }
    .title-product{
        grid-area: title;
    }
    .price-unit{
        grid-area: price_u;
    }
    .quantity{
        grid-area: cant;
        gap:0;
    }
    .price_total{
        grid-area: price_t;
    }
    .delete{
        grid-area: delete;
        text-align: end;
    }
    .btn-minus, .btn-plus{
        font-size: 1.5rem;
    }



}
